// import axios from "@/commons/libs/request.js";
import axios from 'axios';

//const API_ROOT = "http://tlcms.t.lsctl.com/api/";
const API_ROOT = "../api/";
// const API_ROOT = process.env.NODE_ENV == 'development' ? 'http://tlcms.t.lsctl.com/api/' : '../api/';
const API_SSO = process.env.NODE_ENV == 'development' ? 'http://sso.i.lsctl.com/api/' : "../sso/api/"
//
// 修改密码
export const userReset = params => {
  return axios.post(`${API_SSO}user/reset`, params).then(res => res.data).catch(error => {
    console.log(error)
  });
} //修改密码

//拿tokeykey换取token
export const obtainToken = async function (params) {
  return await axios.get(`${API_SSO}token/exchange`, {
    params: params
  }).then(res => res.data).catch(error => {
    console.log(error);
  })
};

//退出登录
export const logout = async function (params) {
  return await axios.post(`${API_SSO}token/logout`, params).then(res => res.data).catch(error => {
    console.log(error);
  })
};

//联系我们
export const ContactAdd = async function (params) {
  return await axios.post(`${API_ROOT}Contact/add`, params).then(res => res.data).catch(error => {
    console.log(error);
  })
};

// ==================
//查询新闻侧边栏
export const addlabel = params => {
  return axios.get(`${API_ROOT}label/findAll`, params).then(res => res.data).catch(error => {
    console.log(error)
  });
}

// 查询新闻列表以及详情
export const articleList = params => {
  return axios.post(`${API_ROOT}article/findAll`, params).then(res => res.data).catch(error => {
    console.log(error)
  });
}

//查询特定标签的新闻列表   labelId:1 //标签id
export const getByLabelId = params => {
  return axios.get(`${API_ROOT}article/getByLabelId`, {
    params: params
  }).then(res => res.data).catch(error => {
    console.log(error)
  });
}

//文章增加浏览量   articlid:1 //文章id
export const articleAddpv = params => {
  return axios.get(`${API_ROOT}article/addpv`, {
    params: params
  }).then(res => res.data).catch(error => {
    console.log(error)
  });
}
// ===================


//查询所有产品
export const getfindVoAlL = params => {
  return axios.get(`${API_ROOT}product/findVoAlL`, {
    params: params
  }).then(res => res.data).catch(error => {
    console.log(error)
  });
}

// 查询关于我们模块内容
export const AboutFindAll = params => {
  return axios.post(`${API_ROOT}about/findAll`, params).then(res => res.data).catch(error => {
    console.log(error)
  });
} //查

//轮播图
export const slideShowfindAll = params => {
  return axios.post(`${API_ROOT}slideShow/findAll `, params).then(res => res.data).catch(error => {
    console.log(error)
  });
} //查
//联系我们
export const ContactDetail = params => {
  return axios.get(`${API_ROOT}Contact/detail`, params).then(res => res.data).catch(error => {
    console.log(error)
  });
} //查

// 查询招贤纳士模块内容
export const RecruitFindAll = params => {
  return axios.post(`${API_ROOT}Recruitment/findAll`, params).then(res => res.data).catch(error => {
    console.log(error)
  });
} //查



// 查询招贤纳士模块内容
